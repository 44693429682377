import classNames from 'classnames'
import React, { FC, ReactNode } from 'react'
import ReactModal from 'react-modal'

import Button, { ButtonProps } from '../buttons/Button'
import CloseButton from '../buttons/CloseButton'
import Message from '../Message'
import Modal, { ModalBody, ModalFooter, ModalHeader } from '../Modal'
import Typography from '../Typography'

export interface LimitOrderCancelModalProps extends ReactModal.Props {
  title: string
  className: string
  onClose?: () => void
  modalActions?: ButtonProps[]
  error?: ReactNode
}

const LimitOrderCancelModal: FC<LimitOrderCancelModalProps> = (props) => {
  return (
    <ReactModal {...props}>
      <Modal>
        {props.onClose && <CloseButton onClick={props.onClose} className="modal__close" />}
        <ModalHeader>
          <Typography variant="h3" title={props.title} className="modal__title" />
        </ModalHeader>
        <ModalBody>{props.children}</ModalBody>
        {props.error && (
          <ModalBody>
            <Message type="error">{props.error}</Message>
          </ModalBody>
        )}
        {props.modalActions && (
          <ModalFooter>
            <div className="row row--justify-center">
              {props.modalActions.map((buttonProps) => {
                return (
                  <div className="cell cell--auto" key={buttonProps.caption}>
                    <Button
                      {...buttonProps}
                      className={classNames('modal__action', buttonProps.className)}
                    />
                  </div>
                )
              })}
            </div>
          </ModalFooter>
        )}
      </Modal>
    </ReactModal>
  )
}

export default LimitOrderCancelModal

import {
  LimitOrderBuilder,
  LimitOrderPredicateBuilder,
  LimitOrderProtocolFacade,
  Web3ProviderConnector,
} from '@1inch/limit-order-protocol'
import { useWeb3React } from '@telekomconsalting/core'
import BigNumber from 'bignumber.js'
import { FC, useState } from 'react'
import Modal from 'react-modal'
import { useDispatch, useSelector } from 'react-redux'
import Select, { SingleValue } from 'react-select'

import { getLimitOrdersData } from '../../actions/limitOrderActions'
import { AmplitudeEvent } from '../../constants/amplitudeEvents'
import { getRootElement } from '../../helpers/appHelpers'
import { financialFormat } from '../../helpers/priceAndAmountHelpers'
import { TokenWithApprovalContext } from '../../model/tokenModel'
import { Transaction } from '../../model/transactionModel'
import { State } from '../../reducers'
import amplitudeService from '../../services/amplitudeService'
import apiClient from '../../services/ApiClient'
import { contractAddress } from '../../services/NFTService/resources'
import { calculateTokenUsdPrices, pickTokenFrom, pickTokenTo } from '../../services/tokenService'
import { getMarketDisplayName, getNetworkConfigByChainId } from '../../utils'
import Button from '../buttons/Button'
import CloseButton from '../buttons/CloseButton'
import { Card, CardBody } from '../Card'
import SwapSummary from '../SwapSummary'
import { TooltipAnchor } from '../TooltipAnchor'

const defaultExpirationPeriod = { value: 60 * 60 * 1000, label: '1 hour' }

const LimitOrderConfirmationModal: FC<{
  onClose: () => void
  onComplete?: () => void
}> = ({ onClose }) => {
  const dispatch = useDispatch()

  const { account, chainId, library } = useWeb3React()
  const [isLoading, setLoading] = useState(false)
  const networkConfig = getNetworkConfigByChainId(chainId)

  const currentToken = useSelector(
    (state: State) => state.tokens.currentToken
  ) as TokenWithApprovalContext
  const quoteToken = useSelector(
    (state: State) => state.tokens.quoteToken
  ) as TokenWithApprovalContext
  const activeTradeType = useSelector((store: State) => store.activeTradeType)

  const txn: Transaction = useSelector((state: State) => state.txn[activeTradeType])
  const [expirationPeriod, setExpirationPeriod] =
    useState<SingleValue<{ value: number; label: string }>>(defaultExpirationPeriod)
  if (!txn.quoteResponse || !txn.price || !networkConfig || !account) {
    return null
  }

  const tokenFrom = pickTokenFrom(activeTradeType, { currentToken, quoteToken })
  const tokenTo = pickTokenTo(activeTradeType, { currentToken, quoteToken })

  const fromAmountBaseUnits = new BigNumber(txn.quoteResponse.sellAmount)
  const toAmountBaseUnits = new BigNumber(txn.quoteResponse.buyAmount)
  const fromAmount = fromAmountBaseUnits.div(10 ** tokenFrom.decimals)
  const toAmount = toAmountBaseUnits.div(10 ** tokenTo.decimals)

  const { fromTokenUsdPrice, toTokenUsdPrice } = calculateTokenUsdPrices({ tokenFrom, tokenTo })

  const fromAmountSelectedCurrency = fromAmount.times(fromTokenUsdPrice)
  const toAmountSelectedCurrency = toAmount.times(toTokenUsdPrice)
  const delta = toAmountSelectedCurrency.div(fromAmountSelectedCurrency).times(100)

  const clearStyles = new Proxy(
    {},
    {
      get: () => (): void => {
        // do nothing
      },
    }
  )
  const handleConfirm = async (): Promise<void> => {
    const connector = new Web3ProviderConnector(library)
    const oneInchAddress = networkConfig.one_inch_api.spender_address
    const oneInchApiUrl = networkConfig.one_inch_api.url
    if (!oneInchAddress || !chainId || !oneInchApiUrl) {
      console.error('oneInchAddress or chainId or oneInchApiUrl was not found')
      return
    }

    setLoading(true)

    const limitOrderProtocolFacade = new LimitOrderProtocolFacade(contractAddress, connector)
    const limitOrderPredicateBuilder = new LimitOrderPredicateBuilder(limitOrderProtocolFacade)

    const expiresWithin = expirationPeriod?.value || defaultExpirationPeriod.value
    const expirationDateUnix = Math.floor((Date.now() + expiresWithin) / 1000)
    const predicate = limitOrderPredicateBuilder.timestampBelow(expirationDateUnix)
    const limitOrderBuilder = new LimitOrderBuilder(oneInchAddress, chainId, connector)
    const limitOrder = limitOrderBuilder.buildLimitOrder({
      makerAssetAddress: tokenFrom.address,
      takerAssetAddress: tokenTo.address,
      makerAddress: account,
      makerAmount: fromAmountBaseUnits.toFixed(0, 1),
      takerAmount: toAmountBaseUnits.toFixed(0, 1),
      predicate: predicate,
    })

    const limitOrderTypedData = limitOrderBuilder.buildLimitOrderTypedData(limitOrder)
    const limitOrderSignature = await limitOrderBuilder
      .buildOrderSignature(account, limitOrderTypedData)
      .catch(() => {
        setLoading(false)
      })

    if (!limitOrderSignature) {
      return
    }

    const limitOrderHash = limitOrderBuilder.buildLimitOrderHash(limitOrderTypedData)

    await apiClient.limitOrders
      .sendLimitOrder({
        orderHash: limitOrderHash,
        signature: limitOrderSignature,
        data: limitOrder,
        oneInchApiUrl: oneInchApiUrl,
      })
      .then((response) => {
        if (!response.error) {
          amplitudeService.sendEvent(AmplitudeEvent.LIMIT_ORDER_SENT, {
            token_id: tokenFrom.id,
            token_pair: tokenTo.id,
            network: currentToken.network.toUpperCase(),
          })
        }
        dispatch(getLimitOrdersData(account, currentToken.id))
        handleClose()
      })

    setLoading(false)
  }

  const handleClose = (): void => {
    setLoading(false)
    onClose()
  }

  return (
    <Modal
      parentSelector={getRootElement}
      isOpen={true}
      onRequestClose={onClose}
      overlayClassName="modal-overlay modal-overlay--swap"
      className="modal-content">
      <div className="modal swap-verify">
        <div className="modal__close" onClick={onClose}>
          <CloseButton />
        </div>
        <div className="modal__header">
          <h4 className="modal__title">Please confirm your limit order!</h4>
        </div>
        <div className="modal__body">
          <SwapSummary
            tokenFrom={tokenFrom}
            tokenTo={tokenTo}
            tokenNetwork={currentToken?.network}
            fromAmount={fromAmount}
            fromAmountSelectedCurrency={fromAmountSelectedCurrency}
            toAmount={toAmount}
            toAmountSelectedCurrency={toAmountSelectedCurrency}
            delta={delta.dp(2).toNumber()}
            isLimitOrder={true}
          />
          <Card type="dark">
            <CardBody>
              <div className="swap-verify-data">
                <div className="swap-verify-data__header">
                  <div className="row row--no-gap row--align-center">
                    <span className="label">Expires in:</span>
                    <TooltipAnchor tooltip="Lorem Ipsum" />
                  </div>
                </div>
                <div className="swap-verify-data__body">
                  <Select
                    defaultValue={defaultExpirationPeriod}
                    className="Select Select--lg Select--menu-separate"
                    classNamePrefix="Select"
                    options={[
                      { value: 10 * 60 * 1000, label: '10 minutes' },
                      { value: 60 * 60 * 1000, label: '1 hour' },
                      { value: 24 * 60 * 60 * 1000, label: '24 hours' },
                      { value: 3 * 24 * 60 * 60 * 1000, label: '3 days' },
                    ]}
                    isMulti={false}
                    isSearchable={false}
                    styles={clearStyles}
                    onChange={(value): void => setExpirationPeriod(value)}
                  />
                </div>
              </div>
              <div className="swap-verify-data">
                <div className="swap-verify-data__header">
                  <div className="row row--no-gap row--align-center">
                    <span className="label">Rate:</span>
                    <TooltipAnchor tooltip="Lorem Ipsum" />
                  </div>
                </div>
                <div className="swap-verify-data__body">
                  <div className="swap-verify__token-amount">
                    <div className="swap-verify__rate">
                      {1} <span className="sign">{getMarketDisplayName(tokenFrom)} @</span>{' '}
                      {financialFormat(txn.price, {
                        highPrecession: true,
                        decimals: new BigNumber(txn.price).lt(1) ? 7 : 4,
                      })}{' '}
                      <span className="sign">{getMarketDisplayName(tokenTo)}</span>
                    </div>
                    <div className="swap-verify__rate">
                      {1} <span className="sign">{getMarketDisplayName(tokenTo)} @</span>{' '}
                      {financialFormat(new BigNumber(1).div(txn.price), {
                        highPrecession: true,
                        decimals: new BigNumber(1).div(txn.price).lt(1) ? 7 : 4,
                      })}{' '}
                      <span className="sign">{getMarketDisplayName(tokenFrom)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
        <div className="modal__footer">
          <div className="row row--justify-center">
            <div className="cell cell--auto">
              <Button
                className="button--accept button--lg modal__action"
                caption={isLoading ? undefined : 'Confirm'}
                disabled={isLoading}
                pending={isLoading}
                onClick={handleConfirm}
              />
            </div>
            <div className="cell cell--auto">
              <Button
                className="button--decline button--lg modal__action"
                disabled={isLoading}
                onClick={handleClose}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LimitOrderConfirmationModal
